var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAuthenticated)?_c('div',{staticClass:"d-flex flex-column flex-root"},[_c('KTHeaderMobile'),(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-column-fluid page"},[(_vm.asideEnabled)?_c('KTAside'):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper",attrs:{"id":"kt_wrapper"}},[_c('KTHeader'),_c('div',{staticClass:"content d-flex flex-column flex-column-fluid",attrs:{"id":"kt_content"}},[(_vm.subheaderDisplay)?_c('KTSubheader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":_vm.pageTitle}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{class:{
              'container-fluid': _vm.contentFluid,
              container: !_vm.contentFluid,
            }},[(
                _vm.tenantConfig.is_subscribe == 0 && _vm.tenantConfig.diff_date > 0
              )?_c('b-alert',{staticStyle:{"background-color":"#245590","border":"none","border-radius":"30px"},attrs:{"show":""}},[_vm._v("Anda sedang dalam masa coba gratis dan tersisa "+_vm._s(_vm.tenantConfig.diff_date)+" hari, "),_c('u',{on:{"click":function($event){return _vm.$router.push('/subscribe/package')}}},[_vm._v("Langganan sekarang")])]):_vm._e(),(
                _vm.tenantConfig.is_subscribe == 1 &&
                _vm.tenantConfig.diff_date < 8 &&
                _vm.tenantConfig.diff_date >= 1
              )?_c('b-alert',{staticStyle:{"background-color":"#245590","border":"none","border-radius":"30px"},attrs:{"show":""}},[_vm._v("Masa berlangganan anda tersisa "+_vm._s(_vm.tenantConfig.diff_date)+" hari, "),_c('u',{on:{"click":function($event){return _vm.$router.push('/subscribe/package')}}},[_vm._v("Langganan sekarang")])]):_vm._e(),(
                _vm.tenantConfig.is_subscribe == 1 && _vm.tenantConfig.diff_date < 1
              )?_c('b-alert',{staticStyle:{"background-color":"#245590","border":"none","border-radius":"30px"},attrs:{"show":""}},[_vm._v("Masa berlangganan anda telah habis, silahkan lankukan perpanjangan. "),_c('u',{on:{"click":function($event){return _vm.$router.push('/subscribe/package')}}},[_vm._v("Perpanjang sekarang")])]):_vm._e(),(
                _vm.tenantConfig.is_subscribe == 0 && _vm.tenantConfig.diff_date < 1
              )?_c('b-alert',{staticStyle:{"background-color":"#245590","border":"none","border-radius":"30px"},attrs:{"show":""}},[_vm._v("Masa coba gratis anda telah habis, silahkan "),_c('u',{on:{"click":function($event){return _vm.$router.push('/subscribe/package')}}},[_vm._v("Langganan sekarang")])]):_vm._e(),_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)])],1),_c('KTFooter')],1)],1),_c('KTScrollTop')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }