<template>
  <div>
    <!-- LOGIN -->
    <!-- logo white -->
    <div
      class="d-flex flex-column-auto flex-column pt-lg-10 pt-10"
      v-if="place == 'login-white'"
    >
      <a
        href="#"
        class="text-center mb-3"
      >
        <img
          src="/images/login/iona-white.png"
          class="max-h-150px"
          alt=""
        />
      </a>
    </div>
    <!-- end logo white -->

    <!-- logo -->
    <div
      class="pb-13 pt-lg-0 pt-5 d-flex justify-content-center"
      v-if="place == 'login'"
    >
      <img
        :src="data.logo"
        width="280"
        max-height="150"
        alt=""
      />
    </div>
    <!-- end logo -->
    <!-- end LOGIN -->

    <!-- Print Medical Record -->
    <template v-if="place == 'print-medical-record'">
      <img :src="data.logo" width="300" class="mt-8" alt="Logo" />
    </template>

    <!-- General -->
    <!-- brand logo -->
    <div
      class="brand-logo"
      v-if="place == 'brand'"
    >
      <router-link to="/">
        <!-- <img :src="siteLogo()" alt="Logo" /> -->
        <img
          :src="data.brand_logo"
          width="180"
          class="mt-8"
          alt="Logo"
        />
      </router-link>
    </div>
    <!-- end brand logo -->
    <!-- end General -->

    <!-- print out section -->
    <!-- medical record -->
    <div
      class="col-md-12 d-flex justify-content-center"
      v-if="place == 'logo-themplate'"
    >
      <img
        :src="data.logo"
        width="300"
        class="mt-8"
        alt="Logo"
      />
    </div>
    <!-- end medical record -->

    <!-- invoice -->
    <div class="d-flex justify-content-center">
      <img
        :src="data.logo"
        height="60"
        style="margin-left: -10px"
        v-if="place == 'logo-invoice'"
      />
    </div>
    <!-- end invoice -->

    <!-- invoice thermal print-->
    <div class="d-flex justify-content-center">
      <img
        :src="data.logo"
        height="130"
        style="margin-left: -10px"
        v-if="place == 'logo-invoice-thermal-print'"
      />
    </div>
    <!-- end invoice -->

    <!-- po invoice -->
    <div
      class="d-flex justify-content-end"
      v-if="place == 'logo-po'"
    >
      <img
        :src="data.logo"
        width="200"
      />
    </div>
    
    <!-- end po invoice -->

    <!-- logo default Medhop -->
    <div
      class="d-flex justify-content-end"
      v-if="place == 'logo-default'"
    >
      <img
        src="https://is3.cloudhost.id/medhop-prod-bucket/tenant-iona-demo/settings/IONA/logo/2022/07/1657605524____Logo%20Transparant-01.png"
        width="150"
        class="my-2"
      />
    </div>
    <!-- end logo default medhop -->

    <!-- end print out section -->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { setConfiguration, getConfiguration } from "@/core/services/jwt.service.js";

export default {
  props: {
    height: Number,
    width: Number,
    place: String,
  },

  data() {
    return {
      // data
      primary: "/images/iona-clinic.png",
      data: getConfiguration() != null ? getConfiguration() : '',

    };
  },
  methods: {
    async get() {
      this.data = await module.get("settings-no-auth/1");
    },

    async getSetting() {
      let dbconfiguration = JSON.parse(localStorage.getItem("dbconfiguration"))
      let response = await module.get("settings-no-auth/1")
      if (response != null) {
        setConfiguration(JSON.stringify(response))
        this.data = response
      }
    },
  },
  mounted() {
    if (this.data == '') {
      this.getSetting()
    }
    

    this.$root.$on("configurationEditSuccessfully", () => {
      this.get()
    });
  }
};
</script>