var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-home mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),(_vm.eksekutif_dashboard)?_c('router-link',{attrs:{"to":"/central-dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-chart-bar mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Eksekutif Dashboard")])])])]}}],null,false,2839974845)}):_vm._e(),(_vm.polyclinic)?_c('router-link',{attrs:{"to":"/polyclinic"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-heartbeat mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Poliklinik")])])])]}}],null,false,567916692)}):_vm._e(),(_vm.my_appointment)?_c('router-link',{attrs:{"to":"/my-appointment"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-tasks mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Perjanjian Saya")])])])]}}],null,false,3257948622)}):_vm._e(),(_vm.appointment || _vm.schedule || _vm.patient || _vm.doctor)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Dokter dan Pasien")]),_c('i',{staticClass:"menu-icon flaticon-more-v2 menu-icon"})]):_vm._e(),(_vm.appointment)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/appointment') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.appointment_list)?_c('router-link',{attrs:{"to":"/appointment/list/0"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Lihat Semua")])])])]}}],null,false,3403050662)}):_vm._e(),(_vm.appointment_add)?_c('router-link',{attrs:{"to":"/appointment/add"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Daftar atau Buat Janji")])])])]}}],null,false,3483362864)}):_vm._e(),(_vm.appointment_today)?_c('router-link',{attrs:{"to":"/appointment/todays"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Hari Ini")])])])]}}],null,false,4106597101)}):_vm._e(),(_vm.appointment_upcoming)?_c('router-link',{attrs:{"to":"/appointment/upcoming"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Akan Datang")])])])]}}],null,false,1522900429)}):_vm._e(),(_vm.appointment_calendar)?_c('router-link',{attrs:{"to":"/appointment/calendar"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Kalender Perjanjian")])])])]}}],null,false,284506017)}):_vm._e(),(_vm.appointment_calendar)?_c('router-link',{attrs:{"to":"/appointment/room-calendar"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Kalender Ruangan")])])])]}}],null,false,1471973871)}):_vm._e(),(_vm.master_appointment_status)?_c('router-link',{attrs:{"to":"/masters/appointment-statuses/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Status Perjanjian")])])])]}}],null,false,1749409867)}):_vm._e()],1)])]):_vm._e(),(_vm.schedule)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        'schedule/list',
        'schedule/holidays',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.schedule_list)?_c('router-link',{attrs:{"to":"/schedule/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Semua Jadwal")])])])]}}],null,false,2703882795)}):_vm._e(),(_vm.schedule_holiday)?_c('router-link',{attrs:{"to":"/schedule/holidays"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Hari Libur")])])])]}}],null,false,1873478627)}):_vm._e()],1)])]):_vm._e(),(_vm.patient)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        'patient/list',
        'patient/cases',
        'patient/cases-print',
        'patient/documents',
        'prescriptions/list',
        'patient/themplate-print',
        'patient/distribution-map',
        'medical-checkup/list'
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(2),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.patient_list)?_c('router-link',{attrs:{"to":"/patient/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-icon",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Daftar Pasien")])])])]}}],null,false,2428452384)}):_vm._e(),(_vm.patient_case)?_c('router-link',{attrs:{"to":"/patient/cases"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Rekam Medis")])])])]}}],null,false,1678187447)}):_vm._e(),(_vm.patient_case_print)?_c('router-link',{attrs:{"to":"/patient/themplate-print"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Cetak Rekam Medis")])])])]}}],null,false,644990991)}):_vm._e(),(_vm.patient_document)?_c('router-link',{attrs:{"to":"/patient/documents"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Dokumen")])])])]}}],null,false,559363682)}):_vm._e(),(_vm.prescription)?_c('router-link',{attrs:{"to":"/prescriptions/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Resep Obat")])])])]}}],null,false,2988487864)}):_vm._e(),(_vm.prescription)?_c('router-link',{attrs:{"to":"/patient/distribution-map"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Peta Sebaran")])])])]}}],null,false,1780366489)}):_vm._e(),(_vm.tenantConfig.subscribe_package_id != 4)?_c('router-link',{attrs:{"to":"/medical-checkup"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Medical Check Up")])])])]}}],null,false,4096067737)}):_vm._e()],1)])]):_vm._e(),(_vm.doctor)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/doctor') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(3),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.doctor_list)?_c('router-link',{attrs:{"to":"/doctor/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot menu-icon"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Daftar Dokter")])])])]}}],null,false,787671587)}):_vm._e(),(_vm.doctor_treatment)?_c('router-link',{attrs:{"to":"/doctor/treatments"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot menu-icon"},[_c('span',{staticClass:"sub-menu-icon"})]),_c('span',{staticClass:"menu-text"},[_vm._v("Riwayat Pengobatan")])])])]}}],null,false,3181542868)}):_vm._e()],1)])]):_vm._e(),(_vm.payment || _vm.human_resource_employee)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Sales")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.payment)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        'payment/list',
        'payment/add',
        'payment/edit',
        'payment/detail',
        'masters/payment-methods/list',
        'masters/banks/list'
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(4),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.payment_list)?_c('router-link',{attrs:{"to":"/payment/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Invoice & Pembayaran")])])])]}}],null,false,671770744)}):_vm._e(),(_vm.payment_add)?_c('router-link',{attrs:{"to":"/payment/add"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Buat Invoice")])])])]}}],null,false,3782517666)}):_vm._e(),(_vm.master_payment_method)?_c('router-link',{attrs:{"to":"/masters/banks/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Jenis Bank (EDC) ")])])])]}}],null,false,52299311)}):_vm._e()],1)])]):_vm._e(),(_vm.tenantConfig.subscribe_package_id != 4)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        '/payment-categories',
        '/masters/payment-category-types/list',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(5),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.human_resource_employee)?_c('router-link',{attrs:{"to":"/payment-categories/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Daftar Layanan")])])])]}}],null,false,4017301857)}):_vm._e(),(_vm.human_resource_employee)?_c('router-link',{attrs:{"to":"/payment-categories/add"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Tambah Layanan")])])])]}}],null,false,1445617718)}):_vm._e(),(_vm.master_payment_category_type)?_c('router-link',{attrs:{"to":"/masters/payment-category-types/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Tipe Produk Layanan")])])])]}}],null,false,3199601658)}):_vm._e()],1)])]):_vm._e(),(
      _vm.human_resource_employee == true &&
      _vm.tenantConfig.subscribe_package_id != 4
    )?_c('router-link',{attrs:{"to":"/used-package-service"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-money-bill mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Deposit Pemakaian Layanan")])])])]}}],null,false,2766820627)}):_vm._e(),(_vm.medical_action || _vm.lab || _vm.bed || _vm.blood_bank)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Diskon Promo & Kupon")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.master_payment_method)?_c('router-link',{attrs:{"to":"/masters/coupons/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-percent menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kupon ")])])])]}}],null,false,1936643104)}):_vm._e(),(_vm.master_payment_method)?_c('router-link',{attrs:{"to":"/masters/promos/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-tags menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v("Diskon & Promo ")])])])]}}],null,false,1613968264)}):_vm._e(),_c('router-link',{attrs:{"to":"/efectivity-dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-chart-bar mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Lap. Efektivitas")])])])]}}])}),(_vm.titleDoctorPatient)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Customer Relationship (CRM)")]),_c('i',{staticClass:"menu-icon flaticon-more-v2 menu-icon"})]):_vm._e(),_c('router-link',{attrs:{"to":"/crm/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-birthday-cake mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pasien Ulang Tahun")])])])]}}])}),(_vm.tenantConfig.subscribe_package_id != 4)?_c('router-link',{attrs:{"to":"/crm/list-recently"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-calendar-alt mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pasien Berkunjung Baru-baru ini")])])])]}}],null,false,1867342914)}):_vm._e(),(_vm.tenantConfig.subscribe_package_id != 4)?_c('router-link',{attrs:{"to":"/crm/list-deposit"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-exclamation mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Reminder Deposit Layanan")])])])]}}],null,false,4053819773)}):_vm._e(),(_vm.tenantConfig.subscribe_package_id != 4)?_c('router-link',{attrs:{"to":"/crm/patient-spending-money"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-list-ol mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pasien Teratas")])])])]}}],null,false,2545723132)}):_vm._e(),(_vm.medical_action || _vm.lab || _vm.bed || _vm.blood_bank)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Operasional Pendukung")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.medical_action)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/custom-pages'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(6),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.medical_action_list)?_c('router-link',{attrs:{"to":"/medical-actions/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Laporan Tindakan Medis ")])])])]}}],null,false,1816427994)}):_vm._e(),(_vm.medical_action_add)?_c('router-link',{attrs:{"to":"/medical-actions/add"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Tambah Tindakan Medis ")])])])]}}],null,false,956864646)}):_vm._e(),(_vm.recap_action_report_list)?_c('router-link',{attrs:{"to":"/action-reports/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Rekap Tindakan PerBulan ")])])])]}}],null,false,3960578557)}):_vm._e()],1)])]):_vm._e(),(_vm.lab)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        '/lab-report-2',
        '/lab-procedure-2',
        '/masters/fields/',
        '/masters/lab-categories/'
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(7),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.lab_procedure)?_c('router-link',{attrs:{"to":"/lab-procedure-2/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Daftar Harga Lab")])])])]}}],null,false,2711218055)}):_vm._e(),(_vm.lab_report_list)?_c('router-link',{attrs:{"to":"/lab-report-2/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Laporan Lab")])])])]}}],null,false,3484700529)}):_vm._e(),(_vm.lab_report_add)?_c('router-link',{attrs:{"to":"/lab-report-2/add"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Tambah Laporan Lab")])])])]}}],null,false,2011242626)}):_vm._e(),(_vm.fields)?_c('router-link',{attrs:{"to":"/masters/fields"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Parameter Pemeriksaan Klinik")])])])]}}],null,false,3675427754)}):_vm._e(),(_vm.master_lab_category)?_c('router-link',{attrs:{"to":"/masters/lab-categories/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Kategori Lab")])])])]}}],null,false,1506383542)}):_vm._e()],1)])]):_vm._e(),(_vm.bed)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        '/masters/rooms/',
        '/masters/buildings/',
        '/beds/',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(8),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.bed_list)?_c('router-link',{attrs:{"to":"/beds/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Daftar Bangsal ")])])])]}}],null,false,2063526881)}):_vm._e(),(_vm.bed_allotment)?_c('router-link',{attrs:{"to":"/beds/allotment"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Pemakaian ")])])])]}}],null,false,3311674852)}):_vm._e(),(_vm.bed_category)?_c('router-link',{attrs:{"to":"/beds/category"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Kategori ")])])])]}}],null,false,3953740825)}):_vm._e(),(_vm.master_building)?_c('router-link',{attrs:{"to":"/masters/buildings/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Gedung")])])])]}}],null,false,1798694891)}):_vm._e(),(_vm.master_room)?_c('router-link',{attrs:{"to":"/masters/rooms/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Ruangan")])])])]}}],null,false,2854654353)}):_vm._e()],1)])]):_vm._e(),(_vm.blood_bank)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        '/blood-bank/',
        '/donors/',
        '/donor-recipients/',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(9),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.blood_bank_list)?_c('router-link',{attrs:{"to":"/blood-bank/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Bank Darah ")])])])]}}],null,false,2892733161)}):_vm._e(),(_vm.donor_list)?_c('router-link',{attrs:{"to":"/donors/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Daftar Pendonor ")])])])]}}],null,false,3144433782)}):_vm._e(),(_vm.donor_recipient)?_c('router-link',{attrs:{"to":"/donor-recipients/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Daftar Penerima Donor ")])])])]}}],null,false,356867812)}):_vm._e()],1)])]):_vm._e(),(_vm.medicine || _vm.pharmacy)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Apotek")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.medicine)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        '/inventory/',
        '/medicine/',
        '/stock-opnames/',
        '/stock-opname-report/',
        '/purchase-orders-pharmacy/'
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(10),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.medicine_list)?_c('router-link',{attrs:{"to":"/medicine/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Daftar Obat dan Alkes")])])])]}}],null,false,1522121110)}):_vm._e(),(_vm.medicine_stock)?_c('router-link',{attrs:{"to":"/medicine/maintain-stock"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Kartu Stok ")])])])]}}],null,false,2540618283)}):_vm._e(),(_vm.stock_opname)?_c('router-link',{attrs:{"to":"/stock-opnames"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Stock Opname ")])])])]}}],null,false,1285207241)}):_vm._e(),(_vm.stock_opname_report_list)?_c('router-link',{attrs:{"to":"/stock-opname-report"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Laporan Stock Opname")])])])]}}],null,false,2525421318)}):_vm._e(),(_vm.medicine_stock)?_c('router-link',{attrs:{"to":"/medicine/medicine-order"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Order Pengambilan Obat ")])])])]}}],null,false,2672384607)}):_vm._e(),(_vm.medicine_list)?_c('router-link',{attrs:{"to":"/medicine/expiration-warning"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Peringatan Kadaluarsa ")])])])]}}],null,false,3944476667)}):_vm._e(),(_vm.pharmacy_po)?_c('router-link',{attrs:{"to":"/purchase-orders-pharmacy"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Surat Pesanan (PO) ")])])])]}}],null,false,2636868104)}):_vm._e(),(_vm.medicine_category)?_c('router-link',{attrs:{"to":"/medicine/category"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Kategori Obat & Alkes")])])])]}}],null,false,379855031)}):_vm._e()],1)])]):_vm._e(),(_vm.pharmacy)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/custom-pages'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(11),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.pharmacy_sales)?_c('router-link',{attrs:{"to":"/pharmacy/sales"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Daftar Penjualan ")])])])]}}],null,false,2866854259)}):_vm._e(),(_vm.pharmacy_cashier)?_c('router-link',{attrs:{"to":"/pharmacy/cashier"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Order ")])])])]}}],null,false,4288646015)}):_vm._e()],1)])]):_vm._e(),(_vm.pharmacy)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/custom-pages'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(12),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.pharmacy_expense_list)?_c('router-link',{attrs:{"to":"/pharmacy/pharmacy-expenses/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Daftar Pengeluaran ")])])])]}}],null,false,317566921)}):_vm._e(),(_vm.pharmacy_expense_add)?_c('router-link',{attrs:{"to":"/pharmacy/pharmacy-expenses/add"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Tambah Pengeluaran ")])])])]}}],null,false,1480887422)}):_vm._e(),(_vm.pharmacy_expense_category_list)?_c('router-link',{attrs:{"to":"/pharmacy/pharmacy-expense-categories/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Kategori Pengeluaran ")])])])]}}],null,false,3306472805)}):_vm._e()],1)])]):_vm._e(),(_vm.medicine || _vm.medicine_stock)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Inventori")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.medicine)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        '/inventory/list',
        '/inventory/add',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(13),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.medicine_list)?_c('router-link',{attrs:{"to":"/inventory/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Daftar Item")])])])]}}],null,false,726260192)}):_vm._e(),(_vm.medicine_add)?_c('router-link',{attrs:{"to":"/inventory/add"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Tambah Item")])])])]}}],null,false,729643159)}):_vm._e()],1)])]):_vm._e(),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        '/inventory/',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(14),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.medicine_stock)?_c('router-link',{attrs:{"to":"/inventory/maintain-stock"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Kartu Stok ")])])])]}}],null,false,2540618283)}):_vm._e(),(_vm.stock_opname)?_c('router-link',{attrs:{"to":"/inventory/stock-opname"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Stock Opname ")])])])]}}],null,false,1285207241)}):_vm._e(),(_vm.stock_opname)?_c('router-link',{attrs:{"to":"/inventory/stock-opname-report"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Laporan Stock Opname ")])])])]}}],null,false,1713977318)}):_vm._e()],1)])]),(_vm.medicine)?_c('router-link',{attrs:{"to":"/inventory/category"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-table mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kategori")])])])]}}],null,false,3808643970)}):_vm._e(),(_vm.medicine)?_c('router-link',{attrs:{"to":"/inventory/purchase-order"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-file-alt mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Pesanan (PO)")])])])]}}],null,false,1528031519)}):_vm._e(),(_vm.medicine)?_c('router-link',{attrs:{"to":"/masters/warehouses/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-warehouse mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Gudang")])])])]}}],null,false,3381738575)}):_vm._e(),(_vm.medicine)?_c('router-link',{attrs:{"to":"/masters/uoms/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-balance-scale mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Satuan")])])])]}}],null,false,3605853211)}):_vm._e(),(_vm.medicine)?_c('router-link',{attrs:{"to":"/medicine/vendors/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-truck-loading mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Supplier")])])])]}}],null,false,1267879440)}):_vm._e(),(_vm.payment || _vm.master_bank_account || _vm.human_resource)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Keuangan & SDM")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.payment)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren([
        'expense-categories/list',
        'expense-categories/add',
        'expense-categories/edit',
        'expense-categories/detail',
        'expense/list',
        'expense/add',
        'expense/edit',
        'expense/detail',
      ]),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(15),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.expense_list)?_c('router-link',{attrs:{"to":"/expense/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Daftar Pengeluaran")])])])]}}],null,false,2061353673)}):_vm._e(),(_vm.expense_category)?_c('router-link',{attrs:{"to":"/expense-categories/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Kategori Pengeluaran")])])])]}}],null,false,2094340581)}):_vm._e()],1)])]):_vm._e(),(_vm.master_bank_account)?_c('router-link',{attrs:{"to":"/masters/bank-accounts/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-money-check-alt mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Rekening Bank")])])])]}}],null,false,549873866)}):_vm._e(),(_vm.human_resource)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/custom-error/error'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(16),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.human_resource_employee)?_c('router-link',{attrs:{"to":"/human-resource/employee"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Karyawan")])])])]}}],null,false,2563321705)}):_vm._e(),(_vm.payroll_list)?_c('router-link',{attrs:{"to":"/payrolls/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Penggajian")])])])]}}],null,false,3728555655)}):_vm._e(),(_vm.remuneration)?_c('router-link',{attrs:{"to":"/masters/remunerations/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Remunerasi")])])])]}}],null,false,566372348)}):_vm._e(),(_vm.remuneration)?_c('router-link',{attrs:{"to":"/masters/remuneration-types/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Tipe Remunerasi")])])])]}}],null,false,2275998644)}):_vm._e(),(_vm.master_department)?_c('router-link',{attrs:{"to":"/masters/departemens/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Departemen")])])])]}}],null,false,1349786404)}):_vm._e(),(_vm.master_position)?_c('router-link',{attrs:{"to":"/masters/positions/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Jabatan")])])])]}}],null,false,3135705186)}):_vm._e()],1)])]):_vm._e(),(_vm.eksekutif_dashboard || _vm.report || _vm.payroll_report_list)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Laporan & Analisa")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.eksekutif_dashboard)?_c('router-link',{attrs:{"to":"/central-dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-chart-bar mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dasbor Eksekutif")])])])]}}],null,false,831956112)}):_vm._e(),(_vm.report)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/custom-error/error'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(17),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.report_payment_report)?_c('router-link',{attrs:{"to":"/report/payment-report"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pendapatan Klinik")])])])]}}],null,false,3944080551)}):_vm._e(),(_vm.report_pharmacy_report)?_c('router-link',{attrs:{"to":"/report/pharmacy-report"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pendapatan Apotek")])])])]}}],null,false,1671309857)}):_vm._e()],1)])]):_vm._e(),(_vm.report)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/custom-error/error'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(18),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.report_expense_report)?_c('router-link',{attrs:{"to":"/report/expense-report"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengeluaran Klinik")])])])]}}],null,false,4058130511)}):_vm._e(),(_vm.report_pharmacy_expense_report)?_c('router-link',{attrs:{"to":"/report/pharmacy-expense-report"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengeluaran Apotek")])])])]}}],null,false,256210697)}):_vm._e()],1)])]):_vm._e(),(_vm.report_doctor_commision)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/custom-error/error'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(19),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.report_doctor_commision)?_c('router-link',{attrs:{"to":"/report/doctor-commision"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Komisi Dokter")])])])]}}],null,false,503372424)}):_vm._e(),(_vm.report_doctor_commision)?_c('router-link',{attrs:{"to":"/report/employee-commision"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Komisi Pegawai")])])])]}}],null,false,2409053735)}):_vm._e()],1)])]):_vm._e(),(_vm.payroll_report_list)?_c('router-link',{attrs:{"to":"/report/payroll-report"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-file mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Laporan Penggajian")])])])]}}],null,false,1628081355)}):_vm._e(),(_vm.user_management || _vm.configuration)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Pengaturan")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.user_management)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/user-managements'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(20),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.user_access)?_c('router-link',{attrs:{"to":"/user-managements/user-access"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Hak Akses Pengguna ")])])])]}}],null,false,935333309)}):_vm._e(),(_vm.position_access)?_c('router-link',{attrs:{"to":"/user-managements/position-access"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Hak Akses Jabatan ")])])])]}}],null,false,1798214351)}):_vm._e()],1)])]):_vm._e(),(_vm.user_management)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/blankboard'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(21),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.announcement)?_c('router-link',{attrs:{"to":"/announcements/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Daftar Pengumuman ")])])])]}}],null,false,2577591238)}):_vm._e(),(_vm.master_announcement_category)?_c('router-link',{attrs:{"to":"/masters/announcement-categories/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Kategori Pengumuman")])])])]}}],null,false,609606538)}):_vm._e()],1)])]):_vm._e(),(_vm.titleWebsite)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Website")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.setting)?_c('router-link',{attrs:{"to":"/website/setting"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-cog mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Setelan")])])])]}}],null,false,3903044923)}):_vm._e(),(_vm.configuration)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/settings/edit'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(22),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/settings/edit"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Konfigurasi ")])])])]}}],null,false,3365020015)})],1)])]):_vm._e(),(_vm.profile)?_c('router-link',{attrs:{"to":"/profile"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-user mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Profile")])])])]}}],null,false,470239534)}):_vm._e(),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren(['/guide', '/help']),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(23),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/guide"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Tutorial ")])])])]}}])}),_c('router-link',{attrs:{"to":"/help"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Kontak ")])])])]}}])})],1)])]),(_vm.satu_sehat_menu)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Satu Sehat")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.satu_sehat_posting)?_c('router-link',{attrs:{"to":"/satu-sehat/posting"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-file-medical mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Posting")])])])]}}],null,false,3581820972)}):_vm._e(),(_vm.satu_sehat_dashboard)?_c('router-link',{attrs:{"to":"/satu-sehat/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-file-medical mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}],null,false,557209808)}):_vm._e(),(_vm.satu_sehat_api)?_c('router-link',{attrs:{"to":"/satu-sehat/access-token"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-file-medical mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kode Akses API")])])])]}}],null,false,1594425126)}):_vm._e(),_vm._m(24),_c('router-link',{attrs:{"to":"/info-clinic"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-info-circle mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Info Klinik")])])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/subscribe'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(25),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.user_access)?_c('router-link',{attrs:{"to":"/billings/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Daftar Invoice Berlangganan ")])])])]}}],null,false,3445893234)}):_vm._e(),(_vm.position_access)?_c('router-link',{attrs:{"to":"/subscribe/package"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Berlangganan")])])])]}}],null,false,313526663)}):_vm._e()],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-clipboard-list menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pendaftaran atau Perjanjian")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-calendar-alt menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v("Jadwal Praktek Dokter")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-user-injured mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pasien")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-user-md mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dokter")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-money-bill-wave menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pembayaran")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-shopping-basket menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v("Produk Layanan")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-diagnoses mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Tindakan Medis ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-flask mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Pemeriksaan Lab")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-procedures mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Bangsal ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-tint mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Bank Darah ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-pills menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Obat dan Alkes ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-mortar-pestle mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Penjualan Apotek ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-file-invoice-dollar mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pengeluaran Apotek ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-pallet menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Daftar Inventori ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-boxes menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Manajemen Stok ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-money-bill-wave menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pengeluaran")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-user-nurse menu-icon mr-5"}),_c('span',{staticClass:"menu-text"},[_vm._v("SDM")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-file mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Laporan Pendapatan")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-file mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Laporan Pengeluaran")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-file mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Laporan Komisi")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-user-cog mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Manajemen Pengguna ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-scroll mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Pengumuman ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-cogs mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Sistem ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-cogs mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Bantuan ")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-title-color"},[_vm._v("Info Klinik & Berlangganan")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-handshake mr-5 menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Berlangganan")]),_c('i',{staticClass:"menu-arrow"})])
}]

export { render, staticRenderFns }